<template>
  <div class="ad-protocol">
    <ad-navs :fixed="false"></ad-navs>

    <div class="content-container">
      <h2 class="title">网页服务协议</h2>
      <section class="main">
        <p>更新日期：2021年4月08日</p>
        <p class="fw">声明</p>
        <p class="indent">
          本协议是甲乙双方就您通过灵鸽平台向雇主企业提供候选人信息服务的相关事项所订立的有效合约。您通过点击确认或以其他方式选择接受本协议，即表示您与灵鸽已达成协议并同意接受本协议的全部约定内容。
        </p>
        <p class="indent">
          在接受本协议之前，请您仔细阅读本协议的全部内容。如果您对本协议的条款有疑问的，请向灵鸽相关顾问进行询问，灵鸽将向您解释条款内容。如果您不同意本协议的任意内容，或者无法准确理解灵鸽对条款的解释，请不要进行后续操作。您同意灵鸽有权根据运营需要在灵鸽平台上公布相应的信息。该等信息一经甲方在灵鸽平台公布，即构成对本协议双方有约束力，若与本协议约定的条款冲突以灵鸽平台公布的信息为准。
        </p>
        <p></p>
        <p class="fw">一、总则</p>
        <p>
          1.1.本协议内容包括协议正文及附件和所有灵鸽已经发布的或将来可能发布的服务规则，平台网站公告以及经乙方点击同意的协议等。以上均为本协议不可分割的一部分，与本协议具有同等法律效力。
        </p>
        <p>
          1.2.当乙方接受本协议成为灵鸽平台的认证猎头后，乙方同意灵鸽获取乙方提供的本人/他人或个人/公司的信息内容(包括但不限于联系人姓名、手机号、电子邮箱、公司名称、公司电话、公司地址等)，灵鸽的系统会自动获取并储存，乙方允许灵鸽在法律法规范围内使用前述信息，并与信息相关人(如认证猎头、候选人等)联系。
        </p>
        <p>
          1.3.乙方在使用灵鸽提供的各项服务的同时，承诺接受并遵守各项相关规则的约定。灵鸽有权根据需要，不时地制订、修改本协议或各类规则，如本协议有任何变更或到期续约等事宜，灵鸽将通过网站公示、邮件通知形式告知服务商。如乙方在查看通知或收到邮件后的三日内未提出书面异议，则通知中的修改自动生效，成为本协议的一部分。登录或继续使用平台服务将表示乙方接受修改后的协议。除另行明确声明外任何使服务范围扩大或功能增强的新内容均受本协议约束。
        </p>
        <p>
          1.4.甲乙双方相互为独立的民事主体，本合作协议约定及履行不构成甲方与乙方构成任何形式的劳动关系或劳务关系。甲乙双方为合作关系，双方为各自的行为独立承担责任。
        </p>
        <p></p>
        <p class="fw">二、定义</p>
        <p>2.1.如无其他约定，以下词语含义适用于本合同整体范围。</p>
        <p>2.2.“认证猎头”:本协议的乙方，即向灵鸽提供候选人信息的用户。</p>
        <p>2.3.“候选人”:符合雇主企业需求，待灵鸽与其沟通的人才。</p>
        <p>
          2.4.“企业”:与灵鸽直接合作，有人才需求并与候选人直接建立关系的企业。
        </p>
        <p>
          2.5.“成功推荐”:认证猎头提供的候选人，通过灵鸽平台成功入职雇主企业并通过保证期。
        </p>
        <p>
          2.6.“有效推荐”:认证猎头提供候选人信息的，视为认证猎头对该候选人做出有效推荐，认证猎头有权获得因该候选人信息产生的推荐服务费用;但认证猎头提供的信息有如下情形之一的，认证猎头不享有有效推荐权。
        </p>
        <p>2.6.1.与灵鸽平台一年内已经获得的信息重合的;</p>
        <p>2.6.2.认证猎头提供的信息与雇主企业一年内已经获得的信息重合的;</p>
        <p>2.6.3.候选人信息内容不真实的;</p>
        <p>2.6.4.候选人信息内容未经授权的。</p>
        <p>
          2.7.“保证期”:候选人入职后在保证期内因任何理由（包括但不限于雇主企业业务变动/裁员/组织架构调整，或因雇主企业违反劳动法，或雇主企业未能有效履行与候选人所签订的聘用合约条款）离开雇主企业的，视为本次推荐不成功。保证期的具体期限以灵鸽平台中该岗位页面所公示的期限为准。
        </p>
        <p>
          2.8.“推荐服务费”:认证猎头成功推荐且在本协议约定的支付条件成就后应当获得的服务费用。
        </p>
        <p></p>
        <p class="fw">三、平台规则</p>
        <p>
          3.1.灵鸽受雇主企业委托，为雇主企业提供人力资源服务，为其猎寻合适的人才，撮合雇主企业与候选人建立直接劳动、劳务或其他关系。
        </p>
        <p>
          3.2.认证猎头拥有丰富的候选人资源，依据雇主企业提供的岗位信息，将符合岗位信息的人才分享至灵鸽平台，提供简历信息或线索，成功推荐且支付条件成就后，可获得相应推荐服务费用。
        </p>
        <p>
          3.3.服务顾问依据灵鸽平台的高效系统匹配，与匹配度相符的雇主企业和候选人沟通，并提供候选人评估报告、面试协调、入职协调、背景调查及其他服务。
        </p>
        <p>
          3.4.认证猎头提供的候选人信息具有时效性，在灵鸽查阅前，认证猎头可以单方撤回相应候选人信息；撤回后，灵鸽将不得通过任何形式查阅、使用该候选人信息。
        </p>
        <p>
          3.5.认证猎头如有违反本协议内容(包括协议正文及附件和所有灵鸽已经发布的或将来可能发布的服务规则)的行为，灵鸽有权对认证猎头进行处罚，处罚包括但不限于限制账户部分或全部功能、冻结账户、注销账户、认证猎头加入平台不良黑名单、不予发放推荐服务费用等方式。
        </p>
        <p>
          3.6.甲乙双方为相互独立的民事主体，本合作协议的约定或履行不构成甲方与乙方成立任何形式的劳动关系或劳务关系，双方在法律上各自独立。双方为各自的行为承担法律上的独立责任。
        </p>
        <p></p>
        <p class="fw">四、甲方权利与义务</p>
        <p>
          4.1.甲方有权监督乙方提供的候选人信息质量，包括但不限于按乙方提供信息的真实性、完整程度、时间期限以及信息的有效性。
        </p>
        <p>
          4.2.若就同一候选人，甲方收到不同注册用户推荐的，则以甲方收到该候选人信息的时间先后顺序来判定该候选人简历的推荐权，先推荐视为有效推荐方;但其他推荐方有证据证明先推荐方的行为违反本协议和甲方平台规则的行为时，有效推荐权顺延至下一推荐时间顺位的注册用户。
        </p>
        <p>
          4.3.保证期内如因雇主企业原因(包括雇主企业业务变动/裁员/组织架构调整，或因雇主企业违反劳动法，或雇主企业未能有效履行与候选人所签订的聘用合约条款)导致候选人离职的，甲方将按本协议第六条支付细则向乙方支付相应推荐服务费。
        </p>
        <p>4.4.甲方应当遵守本协议约定，向乙方支付相应推荐服务费。</p>
        <p></p>
        <p class="fw">五、乙方权利和义务</p>
        <p>
          5.1.乙方需保证其向甲方提供的所有信息真实有效。即乙方在通过甲方向雇主企业推荐候选人之前，应当事先得到该候选人的授权或同意，并对候选人的简历以及相关其他信息进行核实。如乙方违反前述保证，甲方有权依照平台规则对乙方进行处罚。
        </p>
        <p>
          5.2.乙方不得以灵鸽平台或公司名义向任何第三方(包括不限于候选人，企业HR等)做出任何承诺、说明或对外进行经营活动，亦不得使用灵鸽的商标、名称、标志等。
        </p>
        <p>
          5.3.乙方在本协议期间，因故意或过失导致甲方受到的任何损害、赔偿等，全部由乙方承担责任。
        </p>
        <p>
          5.4.乙方应妥善保管灵鸽平台的账户信息，不得外泄账号、密码，或将账号转让、出租、出借给他人使用。若乙方发现其账号遭他人非法使用的或盗用的乙方应及时更换账户密码并立即通知甲方。如因上述引起的不利后果，应由乙方自行承担。
        </p>
        <p>
          5.5.乙方不得向任何第三方通过包括但不限于微信、微博、或任何互联网平台散布任何对甲方不实的言论乙方出现上述情况，经甲方调查核实后，有权利立即终止本协议，同时停止支付乙方所有未付佣金，并承担违约责任。
        </p>
        <p>
          5.6.如乙方未经灵鸽平台授权或同意私下与雇主企业进行线下联系或服务交易，乙方应当承担违约责任，违约金按照所涉候选人年薪的50%计算，并且甲方可以立即解除本协议，依照本平台规则对乙方进行处罚。
        </p>
        <p></p>
        <p class="fw">六、支付细则</p>
        <p>
          6.1.推荐服务费:乙方通过自身账号提交有效候选人信息，并且该候选人入职且通过保证期的，乙方将按岗位页面展示的费用获取推荐服务费。
        </p>
        <p>
          6.2.甲方将为乙方代扣代缴个人所得税，并将扣缴乙方个人所得税后的款项支付给乙方。
        </p>
        <p>
          6.3.有效候选人入职的，甲方将在候选人通过保证期的30个工作日内支付推荐服务费。
        </p>
        <p></p>
        <p class="fw">七、双方关系声明</p>
        <p>
          甲乙双方特此声明:甲乙双方为合作伙伴，乙方并非甲方代理商，亦不是甲方的员工;甲乙双方既不是代理关系、亦不是用工关系，而是合作关系。
        </p>
        <p></p>
        <p class="fw">八、免责声明</p>
        <p>
          8.1.用户在灵鸽平台上发布的所有信息均由用户提供，用户依法应对其提供的任何信息承担全部责任。灵鸽对信息的准确性、完整性、合法性或真实性均不承担任何责任。此外，灵鸽对任何使用或提供本网站信息的商业活动及其风险不承担任何法律责任。
        </p>
        <p>
          8.2.认证猎头用户均经过灵鸽平台身份认证审核。灵鸽平台不以任何形式承诺服务质量，仅以认证猎头在平台的实际统计数据反映其服务水平。如果出现服务不满意企业可以选择与该猎头终止合作，选择其他合作对象。
        </p>
        <p>
          8.3.认证猎头自行选择企业用户合作，并承担相应的风险。若出现法律纠纷，灵鸽平台仅根据法律机构要求提供相关操作数据作为取证。因网络状况、通讯线路、第三方网站等任何原因而导致不能正常使用平台服务，灵鸽平台不承担任何法律责任。
        </p>
        <p>
          8.4.任何单位或个人发现灵鸽平台网页内容可能涉嫌侵犯其网络信息传播权，应该及时向灵鸽平台提出书面权利要求，并提供身份证明、权属证明及详细侵权情况证明。灵鸽平台在收到上述法律文件后，将会依法尽快移除相关涉嫌侵权的内容。反之，灵鸽平台依法不承担任何责任。
        </p>
        <p></p>
        <p class="fw">九、协议终止</p>
        <p>
          9.1.若乙方向灵鸽平台提出注销平台注册认证猎头身份，需经甲方审核同意，由甲方注销该注册认证猎头，乙方即解除与甲方的合作协议关系，但甲方仍保留下列权利:
        </p>
        <p>
          9.1.1.乙方注销后，甲方有权在法律、法规、行政规章规定的时间内保留甲乙方的资料，包括但不限于以前的服务商资料、交易记录等。
        </p>
        <p>
          9.1.2.乙方注销后，若注销前在灵鸽平台上存在违法行为或违反本协议的行为，甲方仍可行使本协议所规定的权利。
        </p>
        <p>9.2.乙方存在下列情况，甲方可以通过注销乙方账户的方式终止合作关系:</p>
        <p>
          9.2.1.在乙方违反本协议及相关规则规定时，甲方有权终止双方的合作。甲方将在终止合作时通知乙方。但乙方在被甲方终止合作关系后，再一次直接或间接或以他人名义注册为灵鸽平台用户的,甲方有权单方面终止为乙方提供任何服务;
        </p>
        <p>
          9.2.2.乙方本人与现在存在劳动关系的单位之间存在不得兼职的有关规定或约定;
        </p>
        <p>9.2.3.本协议终止或更新时，乙方拒绝接受或未确认新的协议的;</p>
        <p>9.2.4.其它甲方认为需终止服务的情况。</p>
        <p></p>
        <p class="fw">十、生效</p>
        <p>
          10.1.本协议经乙方签字或点击本协议电子版“同意”后生效。本协议电子版具有同等的法律效力。
        </p>
        <p>10.2.本协议为长期有效。</p>
        <p></p>
        <p class="fw">
          我已完全阅读并理解本协议全部内容，我接受并同意签署本协议。
        </p>
      </section>
    </div>

    <ad-footer style="margin-top: 100px"></ad-footer>
  </div>
</template>

<script>
// 网页服务协议
export default {
  name: "adProtocolComponent",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.ad-protocol {
  width: 100%;
  padding-top: 130px;
  box-sizing: border-box;
  .content-container {
    max-width: 1180px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    padding-bottom: 100px;
    .title {
      width: 948px;
      margin: 0px auto;
      font-size: 30px;
      font-family: "tj";
      font-weight: 600;
      color: #222222;
      line-height: 42px;
      padding: 100px 0px 59px 0px;
    }
    .main {
      width: 948px;
      margin: 0px auto;
      p {
        font-size: 16px;
        font-family: Noto Sans SC;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
}
</style>